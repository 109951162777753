/* Built In Imports */
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

/* External Imports */
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';
import { FaTimes } from 'react-icons/fa';

/* Internal Imports */
/* Components */
import Search from '@lb/components/Icons/Search';
/* Services */

/**
 *
 * @param {object} sectionContent
 * @returns
 */

const SearchFilterBlog = ({ sectionContent, region, lang, section }) => {
  const [value, setValue] = useState('');
  const router = useRouter();
  const inputRef = useRef('null');
  const [isOpen, setIsOpen] = useState(false);
  const [isCatOpen, setCatIsOpen] = useState(false);
  const [isSort, setSort] = useState(router.query.sort);
  const [topicName, setTopic] = useState('Topics');

  const sortObj = {
    newest: 'Most Recent',
    popularity: 'Popular',
    oldest: 'Oldest',
    '': 'Most Recent',
  };

  useEffect(() => {
    if (router.query.topic) {
      let mainTopic = sectionContent?.topicList?.filter((topic, index) => {
        return topic.uuid === router.query.topic?.toLowerCase().trim();
      });
      if (mainTopic?.length) {
        setTopic(mainTopic[0].title);
      }
    }
  }, []);

  const RightIcon = () => {
    return (
      <>
        <Box
          display="inline-block"
          rounded="full"
          background="#B7987E"
          padding="5px"
          h="auto"
          fontSize="10px"
          w="auto"
        >
          {/* <CloseButton color="#FFFFFF" /> */}
          <FaTimes style={{ color: '#fff' }} />
        </Box>
      </>
    );
  };

  const setFilter = (type, value) => {
    router.push(
      {
        pathname: router.asPath.split('?')[0],
        query: {
          topic: type === 'topic' ? value : router.query.topic,
          sort: type === 'sort' ? value : router.query.sort,
        },
      },
      undefined,
      { scroll: false }
    );
  };

  const background = {
    homepage:
      'linear-gradient(180deg, rgba(43, 0, 6, 0.65) 0%, rgba(72, 0, 10, 0) 100%), rgb(43, 0, 6)',
    'listing-page': 'transparent',
  };

  useEffect(() => {
    sectionContent.topicList?.map((topic, index) => {
      if (topic.uuid === router.query.topic) {
        setTopic(topic.title || topic.name);
      }
    });
  }, []);

  return (
    <Box
      p={{ base: '20px 16px', md: '20px 5%' }}
      maxW="1330px"
      w="100%"
      m="0 auto"
      background={background[sectionContent?.style]}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
    >
      <Box width="100%" height="auto" padding="20px 0">
        <Flex
          w="100%"
          h="auto"
          flexWrap="wrap"
          flexDir={{ base: 'column', md: 'row' }}
          justifyContent="flex-end"
          style={{ gap: '10px' }}
        >
          <Box>
            <Flex
              alignItems="center"
              w="100%"
              height="auto"
              justifyContent="flex-end"
              gridGap="15px"
              maxW="522px"
              flexWrap="wrap"
              mt={{ base: '25px', md: '0' }}
              mb={{ base: '30px', md: '0' }}
            >
              {sectionContent?.searchFilterVisibility && (
                <FormControl
                  border="1px solid #EDD1C2"
                  rounded="full"
                  w={{ base: '100%', sm: '100%', md: '522px' }}
                  maxW="522px"
                  bg="#FFFFFF"
                >
                  <InputGroup
                    color="#B7987E"
                    w="100%"
                    alignItems="center"
                    variant="outline"
                  >
                    <InputLeftElement
                      mt="3px"
                      cursor="pointer"
                      color="#B7987E"
                      ml={{ base: '0', md: '10px' }}
                      fontSize="1.2em"
                    >
                      <Search />
                    </InputLeftElement>
                    <Input
                      id="search"
                      type="text"
                      placeholder={
                        sectionContent?.blogFilterPlaceholderText?.json
                          ?.searchPlaceholder
                      }
                      color="#B7987E"
                      ml={{ base: '10px', md: '18px' }}
                      height="45px"
                      fontFamily="FedraSerifAStdBook"
                      autoComplete="off"
                      _placeholder={{
                        color: '#B7987E',
                        fontWeight: '350',
                        fontSize: '18px',
                        lineHeight: '22px',
                      }}
                      ref={inputRef}
                      w="100%"
                      variant="unstyled"
                      onChange={e => setValue(e.currentTarget.value)}
                      onKeyPress={e => {
                        if (e.key === 'Enter') {
                          router.push({
                            pathname: `/linga-bhairavi/${region}/${lang}/blog/search`,
                            query: {
                              search: value,
                            },
                          });
                        }
                      }}
                    />
                    {value.length > 0 && (
                      <InputRightElement
                        // children={<RightIcon />}
                        cursor="pointer"
                        onClick={e => (inputRef.current.value = '')}
                        // display={value > 0 ? 'block' : 'none'}
                      >
                        <RightIcon />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
              )}
            </Flex>
          </Box>

          <Flex
            // flex={1}
            flexWrap="wrap"
            // w={{ base: '100%', md: '100%', lg: '40%' }}
            w="auto"
            alignItems="flex-end"
            justifyContent={{ base: 'flex-start', md: 'flex-end' }}
            gridGap={{ base: '5px', sm: '10px', md: '10px' }}
          >
            {sectionContent?.sortFilterVisibility && (
              <Button
                bg="#ffffff"
                flex={1}
                border="1px solid #B7987E"
                color="#B7987E"
                w={{ base: '164px', md: '227px' }}
                h={{ base: '36px', md: '46px' }}
                rounded="full"
                display="inline-flex"
                justifyContent="space-between"
                _hover={{ border: '1px solid #B7987E' }}
                _focus={{ border: '1px solid #B7987E' }}
                _active={{ border: '1px solid #B7987E' }}
                rightIcon={isCatOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setCatIsOpen(!isCatOpen)}
                onBlur={() => setCatIsOpen(false)}
                className="selectButton"
                textTransform="capitalize"
                value={sortObj[router.query.sort || '']}
                fontFamily="FedraSerifAStdBook"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {sortObj[router.query.sort || '']}
                </Text>
                {isCatOpen && (
                  <Flex
                    _before={{ content: "''", height: '17px' }}
                    _after={{ content: "''", height: '17px' }}
                    display={isCatOpen ? 'block' : 'none'}
                    _hover={{ display: 'block' }}
                    className="options"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    // onMouseLeave={() => {
                    //   setCatIsOpen(false);
                    //   setSort('Most Recent');
                    // }}
                    onClick={() => setCatIsOpen(false)}
                    position="absolute"
                    top={{ base: '45px', md: '47px' }}
                    border="1px solid #EDD1C2"
                    zIndex="10"
                    left="0"
                    borderRadius="14px"
                    minW={{ base: '192px', md: '227px' }}
                    flexDir="column"
                    bg="#ffffff"
                    mt={{ base: '0', md: '8px' }}
                    w="auto"
                    p="17px"
                  >
                    <Box padding="8px 0px" cursor="pointer">
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '600' }}
                        // onMouseEnter={event => setSort('newest')}
                        onClick={event => {
                          setSort('newest');
                          setFilter('sort', 'newest');
                        }}
                      >
                        {
                          sectionContent?.blogFilterPlaceholderText?.json
                            ?.sortMostRecent
                        }
                      </Text>
                    </Box>
                    <Box padding="8px 0px" cursor="pointer">
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '600' }}
                        // onMouseEnter={event => setSort('popularity')}
                        onClick={() => {
                          setSort('popularity');
                          setFilter('sort', 'popularity');
                        }}
                      >
                        {
                          sectionContent?.blogFilterPlaceholderText?.json
                            ?.sortPopular
                        }
                      </Text>
                    </Box>
                    <Box padding="8px 0px" cursor="pointer">
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '600' }}
                        onClick={() => {
                          setSort('oldest');
                          setFilter('sort', 'oldest');
                        }}
                      >
                        {
                          sectionContent?.blogFilterPlaceholderText?.json
                            ?.sortOldest
                        }
                      </Text>
                    </Box>
                  </Flex>
                )}
              </Button>
            )}

            {sectionContent?.topicFilterVisibility && (
              <Button
                bg="#ffffff"
                flex={1}
                border="1px solid #B7987E"
                color="#B7987E"
                w={{ base: '164px', md: '227px' }}
                rounded="full"
                display="inline-flex"
                justifyContent="space-between"
                _hover={{ border: '1px solid #B7987E' }}
                _focus={{ border: '1px solid #B7987E' }}
                _active={{ border: '1px solid #B7987E' }}
                value={topicName}
                rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                onClick={() => setIsOpen(!isOpen)}
                onBlur={() => setTimeout(() => setIsOpen(false), 1000)}
                className="selectButton"
                textTransform="capitalize"
                h={{ base: '36px', md: '46px' }}
                fontFamily="FedraSerifAStdBook"
              >
                <Text
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {topicName}
                </Text>
                {isOpen && sectionContent?.topicList?.length > 0 && (
                  <Flex
                    _before={{ content: "''", height: '17px' }}
                    _after={{ content: "''", height: '17px' }}
                    display={isOpen ? 'block' : 'none'}
                    _hover={{ display: 'block' }}
                    className="options"
                    alignItems="flex-start"
                    alignContent="flex-start"
                    onClick={() => setIsOpen(!isOpen)}
                    position="absolute"
                    top={{ base: '45px', md: '47px' }}
                    border="1px solid #EDD1C2"
                    zIndex="200"
                    left="0"
                    borderRadius="14px"
                    minW={{ base: '192px', md: '227px' }}
                    flexDir="column"
                    bg="#ffffff"
                    mt={{ base: '0', md: '8px' }}
                    w="auto"
                    p="17px"
                  >
                    <Box padding="8px 0px" key={nanoid()} cursor="pointer">
                      <Text
                        color="#B7987E"
                        fontFamily="FedraSansStd-book"
                        textAlign="left"
                        _hover={{ color: '#A33A2C', fontWeight: '600' }}
                        onClick={() => {
                          setTopic('All Topics');
                          setFilter('topic', '');
                        }}
                      >
                        All Topics
                      </Text>
                    </Box>
                    {sectionContent?.topicList?.map(topic => {
                      return (
                        <Box padding="8px 0px" key={nanoid()} cursor="pointer">
                          <Text
                            color="#B7987E"
                            fontFamily="FedraSansStd-book"
                            textAlign="left"
                            _hover={{ color: '#A33A2C', fontWeight: '600' }}
                            onClick={() => {
                              setTopic(topic.title || topic.name);
                              setFilter('topic', topic.uuid);
                            }}
                          >
                            {topic.title || topic.name}
                          </Text>
                        </Box>
                      );
                    })}
                  </Flex>
                )}
              </Button>
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default SearchFilterBlog;
